import Vue from 'vue'
import Vuex from 'vuex'
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import $bus from '@/utils/bus'
import router from '@/router'
import { login } from '@/api/main'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showMenu: true,
    screen: {},
    aspectRatio: [16, 9], // 宽高比
    subTitle: '', // 子窗口副标题
    platformChildCount: 0, // 本平台记录未成年数量
    signInfo: {}, // 视频会议必要签名
    planelState: true, // 会议面板展示状态(最小化)
    isMutedVideo: false, // 是否开启了摄像头
    isMutedAudio: false, // 是否开启了话筒
    mettingPanel: false, // 是否显示会议面板
    childType: {}, // 未成年人现状分类
    childTypeShow: false, // 现状分类面板是否展示
    lock: !localStorage.getItem('token'), // 锁屏操作
    loginErrMsg: '' // 登录失败信息
  },
  getters: {
    showMenu: state => state.showMenu
  },
  mutations: {
    setChildType(state, type) {
      let zhenc = null
      let count = 0
      type.tagCountList.forEach(item => {
        if (item.name === '正常儿童') {
          zhenc = item
        } else {
          count += item.count
        }
      })
      zhenc.count = type.total - count
      state.childType = type
    },
    setSignInfo(state, info) {
      state.signInfo = info
    },
    changeShow(state, show) {
      state.showMenu = show
    },
    setTitle(state, title) {
      state.subTitle = title
    },
    setPlatformChildList(state, platformChildCount) {
      state.platformChildCount = platformChildCount
    },
    changeScreen(state) {
      const width = screen.width
      const [a, b] = state.aspectRatio
      // 按照16：9  修改此处需要修改混入 drawMixin.js  39行
      const height = (width * b) / a
      state.screen = {
        width: width + 'px',
        height: height + 'px',
        intHeight: height
      }
      console.log(state.screen)
    }
  },
  actions: {
    login({ state }, password) {
      login({
        username: 'mpcenter',
        password
      })
        .then(res => {
          localStorage.setItem('token', res.token)
          router.push('/')
          setTimeout(() => {
            state.lock = false
          }, 3000)
        })
        .catch(err => {
          state.loginErrMsg = err.msg + new Date().getTime()
        })
    },
    // 开启socket连接 监听有无要求进入视频通话
    buildSocketOnJoinMetting({ dispatch }) {
      var socket = new SockJS(process.env.VUE_APP_SOCKET + '/api/bigdata/ws')
      const stompClient = Stomp.over(socket)
      stompClient.connect(
        '',
        '',
        () => {
          console.log('已连接上===============  通话')
          stompClient.subscribe('/topic/meeting/all', res => {
            Vue.prototype
              .$confirm('是否进入', '视频通话邀请', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              })
              .then(() => {
                // 接收会议号消息
                if (router.history.current.fullPath === '/index/remote') {
                  $bus.$emit('open-meeting', res.body)
                } else {
                  router.push(`/index/remote?roomId=${res.body}`)
                }
              })
          })
        },
        () => {
          console.warn('自动重连中.... ')
          setTimeout(() => {
            dispatch('buildSocketOnJoinMetting')
          }, 30000)
        }
      )
    },
    // 订阅首页消息  /topic/operate/all
    indexAvtiveMsg({ dispatch }) {
      var socket = new SockJS(`${process.env.VUE_APP_SOCKET}/api/bigdata/ws`)
      const stompClient = Stomp.over(socket)
      // 大屏
      stompClient.connect(
        '',
        '',
        () => {
          console.log('已连接上===============  大屏数据')
          stompClient.subscribe('/topic/operate/all', res => {
            const body = JSON.parse(res.body)
            const type = ['', '线上办理', '活动组织', '项目管理', '县外新闻', '县内新闻', '机构新闻']
            switch (body.type) {
              case 1:
                $bus.$emit('online', body)
                break
              case 2:
                console.log('activity收到消息', body)
                $bus.$emit('activity', body)
                break
              case 3:
                console.log('project收到消息', body)
                $bus.$emit('project', body)
                break
              case 4:
                console.log('outCountyNews收到消息', body)
                $bus.$emit('outCountyNews', body)
                break
              case 5:
                console.log('inCountyNews收到消息', body)
                $bus.$emit('inCountyNews', body)
                break
              case 6:
                console.log('orgNews收到消息', body)
                $bus.$emit('orgNews', body)
                break
              case 7:
                console.log('收到用户请求更新地图人数')
                $bus.$emit('mapPerson', body)
                break
            }
          })
        },
        () => {
          console.warn('失去连接了,大屏数据')
          setTimeout(() => {
            dispatch('indexAvtiveMsg')
          }, 30000)
        }
      )
    }
  },
  modules: {}
})
