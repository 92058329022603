import request from '@/utils/request'

export function newsList() {
  return request({
    url: '/news/list'
  })
}

export function operateList() {
  return request({
    url: '/operate/list'
  })
}

// 新闻所有的详情接口
export function allDetail({ id, dataId, dataType }) {
  return request({
    url: `/operate/${id}/${dataId}/${dataType}`
  })
}

// 查询子项目信息
export function projectDetail(id) {
  return request({
    url: `/project/${id}`
  })
}

// 活动统计
export function activeStat(type) {
  return request({
    url: `/user/score/chart/${type}`
  })
}

// 查询父项目下的子项目
export function parentProjectList(parentId) {
  return request({
    url: `/project/${parentId}/subList`
  })
}

// 获取活动详情
export function activeDetail(activeId) {
  return request({
    url: `/activity/${activeId}`
  })
}

// 获取活动展示详情
export function activeProgDetail(showId) {
  return request({
    url: `/activity/show/${showId}`
  })
}

// 获取活动积分规则
export function scoreRule() {
  return request({
    url: `/user/score/rule/list`
  })
}

// 获取活动风采详情
export function activeResultDetail(activeId) {
  return request({
    url: `/activity/result/${activeId}`
  })
}

// 获取活动类型
export function activeListByType(data) {
  return request({
    url: `/activity/list`,
    method: 'POST',
    data
  })
}

// 审核通过
export function activePass(data) {
  return request({
    url: `/activity/pass`,
    method: 'POST',
    data
  })
}

// 审核拒绝
export function activeReject(data) {
  return request({
    url: `/activity/reject`,
    method: 'POST',
    data
  })
}

// 获取活动组织详情
export function activeOrganizeDetail(showId) {
  return request({
    url: `/activity/organize/${showId}`
  })
}

//未成年人档案审核通过操作
export function minorInfoAudit(url) {
  return request({
    url
  })
}

//获取活动积分列表
export function activeScoreList(data) {
  return request({
    url: `/user/score/list`,
    method: 'POST',
    data
  })
}

//未成年人档案审核拒绝操作
export function infoAuditReject(data) {
  return request({
    url: '/minor/reject',
    method: 'POST',
    data
  })
}

//诉求处理操作
export function appealHandle(data) {
  return request({
    url: '/online/appeal/handle',
    method: 'POST',
    data
  })
}

//案件处理操作
export function caseHandle(data) {
  return request({
    url: '/online/case-report/handle',
    method: 'POST',
    data
  })
}

// 大屏登录
export function login(data) {
  return request({
    url: '/user/login',
    method: 'POST',
    data
  })
}

// 人才队伍
export function talent(data) {
  return request({
    url: '/talent/list',
    method: 'POST',
    data
  })
}

// 政策法规-办事指南
export function regulation(data, type) {
  return request({
    url: `/regulation/list/${type}`,
    method: 'POST',
    data
  })
}

// 课题研究列表
export function topicList(data) {
  return request({
    url: `/research/result/list`,
    method: 'POST',
    data
  })
}

// 课题研究详情
export function topicDetail(planId) {
  console.log('planId===>', planId)
  return request({
    url: `/research/result/${planId}`
  })
}

// 工作计划列表
export function planList(data) {
  return request({
    url: `/work/plan/list`,
    method: 'POST',
    data
  })
}

// 工作计划详情
export function planDetail(planId) {
  return request({
    url: `/work/plan/${planId}`
  })
}

// 工作进度详情
export function progressDetail(progressId) {
  return request({
    url: `/work/progress/${progressId}`
  })
}

// 工作总结详情
export function getWorkDetail(resultId) {
  return request({
    url: `/work/result/${resultId}`
  })
}

// 建设情况
export function buildList(data) {
  return request({
    url: `/build/progress/list`,
    method: 'POST',
    data
  })
}

// 机构列表
export function deptList(data) {
  return request({
    url: `/dept/list`,
    method: 'POST',
    data
  })
}

// 机构人员列表列表
export function deptPersonList(deptId) {
  return request({
    url: `/dept/${deptId}`
  })
}

//  能分区-根据楼栋查询房间列表
export function roomList(dictCode) {
  return request({
    url: `/build/info/${dictCode}/list`
  })
}

//  房间详情
export function roomDetail(id) {
  return request({
    url: `/build/info/${id}`
  })
}

// 通用字典查询
export function dictType(type) {
  return request({
    url: `/dict/type/${type}`
  })
}

// 视频通话获取人员信息
export function userList(dictCode) {
  return request({
    url: `/bigdata-meeting-organize/list`,
    method: 'POST',
    data: { dictCode }
  })
}

// 机构风采列表
export function centerMienList(data) {
  return request({
    url: `/dept/mien/list`,
    method: 'POST',
    data
  })
}

// 机构风采详情
export function centerMienDetail(id) {
  return request({
    url: `/dept/mien/${id}`
  })
}
