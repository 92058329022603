;(function (doc, win) {
  let docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function () {
      let clientWidth = docEl.clientWidth

      if (!clientWidth) return

      if (clientWidth === 320) {
        docEl.style.fontSize = 24 * (clientWidth / 320) + 'px'
      } else {
        docEl.style.fontSize = 20 * (clientWidth / 320) + 'px'
      }
    }

  if (!doc.addEventListener) return

  win.addEventListener(resizeEvt, recalc, false)

  doc.addEventListener('DOMContentLoaded', recalc, false)
})(document, window)
