<template>
  <div class="bg">
    <div class="title-container"></div>
    <div class="main-container">
      <div>
        <MyMap></MyMap>
      </div>
      <div>
        <!-- <NonageStatistics /> -->
      </div>
      <div></div>
      <!-- <div><NonageInfoList /></div> -->
      <div>yuyuy</div>
      <div>yuyuy</div>
      <div>yuyuy</div>
      <div>yuyuy</div>
    </div>
  </div>
</template>

<script>
import MyMap from '@/components/MyMap'
// import NonageStatistics from '@/views/index/components/Left/NonageStatistics'
// import NonageInfoList from '@/views/index/components/Left/NonageInfoList'

export default {
  // components: { MyMap, NonageStatistics, NonageInfoList },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.bg {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  // background-image: url('~@/assets/image/bg-ditu.png');
  background-color: #010811;
  .title-container {
    height: 7%;
  }
  .main-container {
    display: grid;
    height: 93%;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 10px 10px;
    & > div:nth-child(1) {
      grid-column: 1 / 4;
      grid-row: 1/4;
      overflow: hidden;
    }
    & > div:nth-child(2) {
      grid-column: 4 / 6;
    }
    & > div:nth-child(3) {
      grid-column: 6 / 8;
    }
    & > div:nth-child(4) {
      grid-column: 4 / 6;
    }
    & > div:nth-child(5) {
      grid-column: 6 / 8;
    }
    & > div:nth-child(6) {
      grid-column: 1 / 3;
    }
    & > div:nth-child(7) {
      grid-column: 3 / 6;
    }
    & > div:nth-child(8) {
      grid-column: 6 / 8;
    }
  }
}
</style>
