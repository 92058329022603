<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  mounted() {
    this.$store.commit('changeScreen')
  }
}
</script>

<style lang="less">
.el-message {
  font-size: 0.2rem;
}
* {
  margin: 0;
  padding: 0;
}
html {
  // font-size: 62.5%;
  font-family: ali;
  user-select: none;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// 站酷高端黑
@font-face {
  font-family: 'ku-blank';
  src: url('~@/assets/fonts/ku-blank.ttf');
}

.footer {
  position: absolute;
  display: flex;
  grid-column-gap: 5px;
  z-index: 9999;
  bottom: 0;
  left: 0;
  background-color: #0000003f;
  padding: 3px 10px;
}

.meng {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: none;
  background-color: #000000ad;
  z-index: 8888;
  justify-content: center;
  align-items: center;
}

.meng-me {
  background-color: #000000ad;
  z-index: 8888;
  position: absolute;
  padding: 3px 10px;
  font-size: 15px;
  left: 0;
  bottom: 0;
}

// google字体用于数字显示
@font-face {
  font-family: 'regular';
  src: url('~@/assets/fonts/Regular.ttf');
}

// 一品创享体
@font-face {
  font-family: 'chuang';
  src: url('~@/assets/fonts/chuang.ttf');
}
// 一品创享体
@font-face {
  font-family: 'main';
  src: url('~@/assets/fonts/main.ttf');
}

// 一品创享体
@font-face {
  font-family: 'youshe';
  src: url('~@/assets/fonts/youshe.ttf');
}

/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'ali';
  font-weight: 500;
  src: url('//at.alicdn.com/wf/webfont/RiFmBegC8g0Y/0JP4jnRCyKdY3parJ5wvD.woff2') format('woff2'),
    url('//at.alicdn.com/wf/webfont/RiFmBegC8g0Y/MXaqVRvzzTuTucx3080_B.woff') format('woff');
  font-display: swap;
}
@keyframes test {
  0% {
    transform: scale(1) translateY(0);
  }
  50% {
    transform: scale(0.8) translateY(5px);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}
</style>
