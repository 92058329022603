<template>
  <div class="btn" @click="$emit('click')">
    <a>
      <slot></slot>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.btn {
  .pirmary-button();
}
</style>
