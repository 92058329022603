import request from '@/utils/request'

// 重点关注未成年人对象
export function minorTagData() {
  return request({
    url: '/minorTagData'
  })
}

// 阵地汇总
export function positionData() {
  return request({
    url: '/positionData'
  })
}

// 获取具体阵地详情
export function positionDetail(positionId) {
  return request({
    url: `/position/${positionId}`
  })
}

// 阵地类型列表
export function positionTypes() {
  return request({
    url: '/position/type/list'
  })
}

// 阵地类型下面具体阵地列表
export function positionListByType(data) {
  return request({
    url: '/position/listByType',
    method: 'POST',
    data
  })
}

// 人才队伍 遗弃
export function postData() {
  return request({
    url: '/postData'
  })
}

// 五社联动
export function fiveOrganize() {
  return request({
    url: '/fiveOrganize/chart'
  })
}

// 五社联动类型
export function communityList() {
  return request({
    url: '/fiveOrganize/community/list'
  })
}

// 五社联动类型下具体组织列表
export function organizeList(dictCode) {
  return request({
    url: `/fiveOrganize/${dictCode}/organize/list`
  })
}

// 五社联动类型下具体组织列表根据乡镇
export function organizeListByDistrict(data) {
  return request({
    url: `/fiveOrganize/community/list/with-district`,
    method: 'POST',
    data
  })
}

// 五社联动获取具体组织下面的的人
export function organizePeoples(organizeId) {
  return request({
    url: `/fiveOrganize/${organizeId}`
  })
}

// 重点关注未成年人列表
export function minorData() {
  return request({
    url: '/minorData'
  })
}

// 办件情况
export function caseData() {
  return request({
    url: '/caseData'
  })
}

// 未成年人列表
export function minorListData(data) {
  return request({
    url: '/minorListData',
    method: 'POST',
    data
  })
}

// 未成年人随访记录列表
export function registList(data) {
  return request({
    url: '/minor/registList',
    method: 'POST',
    data
  })
}

// 未成年人单个档案详情
export function minorDetail(minorId) {
  return request({
    url: `/minor/${minorId}`,
    method: 'GET'
  })
}

// 根据标签类型ID查看未成年人列表
export function minorTagList(data) {
  return request({
    url: `/minorListData/tag`,
    method: 'POST',
    data
  })
}

// 根据地区查看未成年人列表
export function minorDistrictList(data) {
  return request({
    url: `/minorListData/district`,
    method: 'POST',
    data
  })
}

// 项目列表 大项目
export function projectListData(data) {
  return request({
    url: `/projectListData`,
    method: 'POST',
    data
  })
}

// 项目列表 子项目 传入大项目的ID
export function subProjectListData(projectId) {
  return request({
    url: `/project/${projectId}/subList`,
    method: 'GET'
  })
}

// 项目详情 子项目详情
export function subProjectDetail(projectId) {
  return request({
    url: `/project/${projectId}`,
    method: 'GET'
  })
}

// 获取乡镇列表
export function townListData() {
  return request({
    url: `/townListData`,
    method: 'GET'
  })
}

// 获取视频会议需要的信息
export function getUserSign(meetingId) {
  return request({
    url: `/meeting/getToken/${meetingId}`,
    method: 'GET'
  })
}

// 主动发起视频会议
export function startMetting() {
  return request({
    url: '/meeting',
    method: 'POST'
  })
}

// 邀请办事人员视频通话
export function inviteMetting(data, meetingId) {
  return request({
    url: `/meeting/${meetingId}/invite`,
    method: 'POST',
    data
  })
}

// 邀普通人员视频通话
export function inviteMettingByUserId(data, meetingId) {
  return request({
    url: `/meeting/${meetingId}/invite/member`,
    method: 'POST',
    data
  })
}

// 获取乡镇列表
export function getDistrct() {
  return request({
    url: '/district/list/1'
  })
}

//获取乡镇办事人员列表
export function getPersonList(data) {
  return request({
    url: '/district/person/list',
    method: 'POST',
    data
  })
}

//根据乡镇ID办事人员列表
export function getPersonListByDistrictId(data, districtId) {
  return request({
    url: `/district/person/${districtId}/list`,
    method: 'POST',
    data
  })
}
