<template>
  <div class="title-group">
    <div class="title"><slot /></div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.title-group {
  font-family: main;
  // background-color: rgba(#27b6ff, 0.1);
  padding: 5px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 6px;
  .title {
    font-size: @middle-text;
    color: #00e8e8;
  }
  .line {
    // width: 0px;
    opacity: 0.9;
    height: 15px;
    background-image: url('~@/assets/image/line-2.png');
    background-repeat: no-repeat;
  }
}
</style>
