<template>
  <div class="title">
    <span><slot></slot></span>
    <!-- <svg width="480" height="40" viewBox="0 0 480 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H480V40H0V0Z" fill="#0652DD" fill-opacity="0.1" />
      <path class="move" d="M44.5884 27L29 18L29 36L44.5884 27Z" fill="#0652DD" fill-opacity="0.8" />
      <path class="move" d="M33.5884 27L18 18L18 36L33.5884 27Z" fill="#0652DD" fill-opacity="0.6" />
      <path d="M22.5884 27L6.99999 18L6.99998 36L22.5884 27Z" fill="#0652DD" fill-opacity="0.4" />
      <circle class="flicker" cx="9" cy="10" r="4" fill="#0652DD" fill-opacity="0.8" />
      <circle class="flicker" cx="24" cy="10" r="4" fill="#0652DD" fill-opacity="0.8" />
      <circle class="flicker" cx="39" cy="10" r="4" fill="#0652DD" fill-opacity="0.8" />
      <path class="line" d="M0 39.5H480" stroke="#0652DD" stroke-opacity="0.8" />
    </svg> -->
    <svg
      width="480"
      style="width: 100%"
      height="40"
      viewBox="0 0 480 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H480V40H0V0Z" fill="#1E90FF" fill-opacity="0.23" />
      <path class="move" d="M44.5884 27L29 18L29 36L44.5884 27Z" fill="#00BFFF" fill-opacity="0.8" />
      <path class="move" d="M33.5884 27L18 18L18 36L33.5884 27Z" fill="#00BFFF" fill-opacity="0.6" />
      <path class="move" d="M22.5884 27L6.99999 18L6.99998 36L22.5884 27Z" fill="#00BFFF" fill-opacity="0.4" />
      <circle class="flicker" cx="9" cy="10" r="4" fill="#00BFFF" fill-opacity="0.8" />
      <circle class="flicker" cx="24" cy="10" r="4" fill="#00BFFF" fill-opacity="0.8" />
      <circle class="flicker" cx="39" cy="10" r="4" fill="#00BFFF" fill-opacity="0.8" />
      <path d="M0 39.5H480" stroke="#00BFFF" stroke-opacity="0.8" />
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.title {
  position: relative;
  font-family: chuang;
  & > span {
    position: absolute;
    font-size: 1.3rem;
    margin-left: 4.4rem;
    top: 45%;
    transform: translateY(-50%);
    color: #fff;
  }
}
.line {
  stroke-dasharray: 480;
  stroke-dashoffset: 480;
  animation: line 5s ease-in-out infinite;
}
@keyframes line {
  to {
    stroke-dashoffset: 0;
  }
  from {
    stroke-dashoffset: 480;
  }
}
.move {
  animation: move 1s ease-in-out infinite alternate;
}
.flicker {
  animation: flicker 1s ease-in-out infinite alternate;
}
@keyframes flicker {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(7px);
  }
}
</style>
