import Vue from 'vue'
import VueRouter from 'vue-router'
import Index2 from '@/views/index/index2.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/test',
    component: () => import('@/views/test')
  },
  // {
  //   path: '/d',
  //   component: Index,
  //   children: [
  //     {
  //       path: 'layout',
  //       component: Layout,
  //       children: [
  //         {
  //           path: 'record',
  //           component: () => import('@/views/record'),
  //           meta: {
  //             title: '未成年人数据'
  //           }
  //         },
  //         {
  //           path: 'position',
  //           component: () => import('@/views/position'),
  //           meta: {
  //             title: '阵地汇总'
  //           }
  //         },
  //         {
  //           path: 'attention/:tagId',
  //           component: () => import('@/views/attention'),
  //           meta: {
  //             title: '重点关注对象'
  //           }
  //         },
  //         {
  //           path: 'area-child/:districtId',
  //           component: () => import('@/views/area-child'),
  //           meta: {
  //             title: '未成年人数据'
  //           }
  //         },
  //         {
  //           path: 'project/:projectId',
  //           component: () => import('@/views/project'),
  //           meta: {
  //             title: '项目管理'
  //           }
  //         },
  //         {
  //           path: 'case',
  //           component: () => import('@/views/case'),
  //           meta: {
  //             title: '办件情况'
  //           }
  //         },
  //         {
  //           path: 'ganged',
  //           component: () => import('@/views/ganged'),
  //           meta: {
  //             title: '五社联动'
  //           }
  //         },
  //         {
  //           path: 'meeting',
  //           component: () => import('@/views/meeting'),
  //           meta: {
  //             title: '视频会议'
  //           }
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    path: '/index2',
    component: Index2
  },
  {
    path: '/',
    component: () => import('@/views/main/layout'),
    redirect: 'index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/main/index'),
        meta: {
          title: '首页'
        },
        children: [
          {
            path: 'news/:id/:dataId/:dataType',
            component: () => import('@/views/main/alters/news'),
            meta: {
              title: '新闻'
            }
          },
          {
            // path: 'news/:id/:dataId/:dataType',
            path: 'activity/:id/:dataType',
            component: () => import('@/views/main/alters/activity'),
            meta: {
              title: '活动'
            }
          },
          {
            path: 'data-manage',
            component: () => import('@/views/main/alters/data-manage'),
            meta: {
              title: '数据管理'
            }
          },
          {
            path: 'center-manage',
            component: () => import('@/views/main/alters/center-manage'),
            meta: {
              title: '中心管理'
            }
          },
          {
            path: 'policy',
            component: () => import('@/views/main/alters/policy-statutes'),
            meta: {
              title: '政策法规'
            }
          },
          {
            path: 'remote',
            component: () => import('@/views/main/alters/remote-interaction'),
            meta: {
              title: '远程互动'
            }
          },
          {
            path: 'dispatch',
            component: () => import('@/views/main/alters/work-dispatch'),
            meta: {
              title: '工作调度'
            }
          },
          {
            path: 'ganged',
            component: () => import('@/views/main/alters/five-club'),
            meta: {
              title: '五社联动'
            }
          },
          {
            path: 'project-manage/:id',
            component: () => import('@/views/main/alters/project-manage'),
            meta: {
              title: '项目管理'
            }
          },
          {
            path: 'appeal-detail/:id/:dataId/:dataType',
            component: () => import('@/views/main/alters/appeal-detail'),
            meta: {
              title: '诉求详情'
            }
          },
          {
            path: 'town/:id/:name',
            component: () => import('@/views/main/alters/town-info'),
            meta: {
              title: '乡镇详情'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/main/login')
  }
  // {
  //   path: '/info',
  //   component: Index,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/info/index.vue')
  //     }
  //   ]
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  if (to.path === '/login') {
    if (token) {
      next('/')
    }
  } else {
    if (!token) {
      next('/login')
    } else {
      store.commit('changeShow', true)
    }
  }
  next()
})

export default router
