<template>
  <div class="contenttt" ref="content" v-html="content"></div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  watch: {
    content(pr) {
      this.$nextTick(() => {
        this.$refs.content.querySelectorAll('span').forEach(item => {
          item.style.color = '#fff'
        })
        this.$refs.content.querySelectorAll('img').forEach(item => {
          item.style.width = '50%'
          item.parentElement.style.textAlign = 'center'
        })
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.content.querySelectorAll('span').forEach(item => {
        item.style.color = '#fff'
      })
      this.$refs.content.querySelectorAll('img').forEach(item => {
        item.style.width = '50%'
        item.parentElement.style.textAlign = 'center'
      })
    })
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.contenttt {
  margin-top: 20px;
  .article-setting();
  font-size: @small-text;
  font-family: main;
}
</style>
