import axios from 'axios'
import router from '@/router'
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000 // 请求超时时间
})

request.interceptors.request.use(config => {
  config.headers['token'] = localStorage.getItem('token')
  return config
})

request.interceptors.response.use(res => {
  const code = res.data.code || 200
  if (code === 200) {
    return res.data
  } else if (code === 401) {
    console.warn('401了==================')
    localStorage.clear()
    router.push('/login')
  } else {
    console.log(`错误了====>code${code} =====>msg:${res.data.msg}`)
  }
  return Promise.reject(res.data)
})

export default request
