<template>
  <div>
    <div id="container"></div>
  </div>
</template>

<script>
import $bus from '@/utils/bus'
import AMapLoader from '@amap/amap-jsapi-loader'
import info from '@/assets/image/info.png'
import location from '@/assets/image/location.png'
import { minorData } from '@/api'
import axios from 'axios'
const icon = {
  // 图标类型，现阶段只支持 image 类型
  type: 'image',
  // 图片 url
  image: 'https://a.amap.com/jsapi_demos/static/demo-center/marker/express2.png',
  // 图片尺寸
  size: [64, 30],
  // 图片相对 position 的锚点，默认为 bottom-center
  anchor: 'center'
}
export default {
  name: 'MyMap',
  data() {
    return {
      map: null,
      markers: [],
      AMap: null,
      timer: null
    }
  },
  mounted() {
    this.initMap()
    $bus.$on('mapPerson', () => {
      this.setChildSite()
    })
  },
  beforeDestroy() {
    $bus.$off('mapPerson')
  },
  methods: {
    // 填充内容设置
    setFitView(AMap, map) {
      axios.get('/chain.json').then(res => {
        res.data.features.map(item => {
          new AMap.Polygon({
            map: map,
            path: item.geometry.coordinates, //设置多边形边界路径
            strokeColor: '#FFF', //线颜色
            strokeOpacity: 1, //线透明度
            strokeWeight: 2, //线宽
            fillColor: '#fff', //填充色
            fillOpacity: 0.1 //填充透明度
          })
          map.setFitView()
          map.setZoom(map.getZoom() + 1.1)
        })
      })
    },
    // 设置乡镇区域儿童总数
    setChildSite() {
      const AMap = this.AMap
      minorData().then(res => {
        this.markers?.forEach(element => {
          this.map.remove(element)
        })
        const { data } = res.data
        const markers = []
        data.forEach((item, index) => {
          const marker = new AMap.Marker({
            position: new AMap.LngLat(item.longitude, item.latitude),
            content: `
            <div title="双击查看" style="display: flex;align-items: center; flex-direction: column;justify-content: center;">
              <div class="info" style="background-size: 100% 100%;object-fit: cover;background-repeat: no-repeat; background-image: url('${info}'); width:170px;padding: 10px 0 10px 10px; color: #fff;font-size: 13px;">
                <div style="font-weight: bold;font-size: 18px">${item.districtName}</div>
                <div style="font-size: 16px">未成年人数：<span style="font-weight: bold">${item.total}</span>人</div>
              </div>
              <img style="width: 20px;height: 30px;animation: test linear 3s infinite;" src="${location}">
            </div>`,
            anchor: 'bottom-center'
          })
          marker.on('dblclick', w => {
            const { districtName, districtId } = item
            this.$router.push(`index/town/${districtId}/${districtName}`)
          })
          marker.on('mouseover', w => {
            this.$store.commit('setChildType', item)
            this.$store.state.childTypeShow = true
            if (this.timer) {
              clearTimeout(this.timer)
              this.timer = null
            }
          })
          marker.on('mouseout', w => {
            if (this.timer) {
              clearTimeout(this.timer)
              this.timer = null
            }
            this.timer = setTimeout(() => {
              this.$store.state.childTypeShow = false
            }, 10000)
          })
          // marker.on('click', w => {
          //   const { longitude, latitude, districtName, districtId } = item
          //   window.mymap.setZoomAndCenter(13, [longitude, latitude], false, 500)
          //   // this.$router.push(`/layout/area-child/${districtId}`)
          //   // this.$store.commit('setTitle', `-${districtName}`)
          // })
          markers.push(marker)
          this.map.add(marker)
        })
        this.markers = markers
      })
    },
    // 初始化地图
    initMap() {
      AMapLoader.load({
        key: '7b61f90d777630a89ee27f0dacbcd921', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then(AMap => {
          const map = new AMap.Map('container', {
            //设置地图容器id
            viewMode: '3D', //是否为3D地图模式
            zoom: 10.4, //初始化地图级别
            pitch: 60, // 地图俯仰角度，有效范围 0-80
            skyColor: '#091322', //设置天空颜色
            terrain: true, // 是否显示地形图
            center: [102.100538, 26.902517], //初始化地图中心点位置
            // mapStyle: 'amap://styles/e1326078e978aabd1e558d90356c1aba',
            mapStyle: 'amap://styles/a3ffcd8e5317e37024c945ece9383596',
            layer: [] // 地图覆盖物
          })
          this.map = map
          this.AMap = AMap
          map.on('click', e => {
            console.log(e)
          })
          window.mymap = map
          this.setFitView(AMap, map)
          this.setChildSite()
        })
        .catch(e => {
          console.log(e)
        })
    }
  }
}
</script>

<style lang="less">
#container {
  width: 1920px;
  height: 1080px;
  cursor: hand !important;
}
.round-group {
  display: flex;
  justify-content: space-between;
  .round {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: skyblue;
    padding: 5px;
    border-radius: 50%;
  }
}
</style>
