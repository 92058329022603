import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CardTitle from '@/components/CardTitle'
import { isMobile } from '@/utils/utils'
import '@/utils/aegis.js'
import '@/components/svgIcon/index.js'
import svgIcon from '@/components/svgIcon/index.vue'

Vue.component('card-title', CardTitle)
import VAnimateCss from 'v-animate-css'
Vue.use(VAnimateCss)
import Button1 from './components/Button1'
import Content from './components/Content'
import Title from './views/main/components/Title'
import 'element-ui/lib/theme-chalk/index.css'
import {
  InfiniteScroll,
  Button,
  Image,
  MessageBox,
  Dialog,
  Empty,
  Input,
  Option,
  Message,
  Loading,
  DatePicker,
  Select
} from 'element-ui'
Vue.use(InfiniteScroll)
Vue.use(Image)
Vue.use(Input)
Vue.use(Empty)
Vue.use(Option)
Vue.use(Select)
Vue.use(Dialog)
Vue.use(Button)
Vue.use(Loading)
Vue.use(DatePicker)
Vue.component('button1', Button1)
Vue.component('content1', Content)
Vue.component('svg-icon', svgIcon)
Vue.component('Title', Title)
// Vue.use(MessageBox)
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message
Vue.prototype.$t = () => {}
Vue.prototype.$isMobile = isMobile
import '@/utils/rem'

import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
import 'animate.css'
Vue.config.productionTip = false
// 连接socket服务 监听视频邀请
new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    store.dispatch('buildSocketOnJoinMetting')
    store.dispatch('indexAvtiveMsg') //  订阅主页动态
  }
}).$mount('#app')
